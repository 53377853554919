import {
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import lineItemStatus from 'common/constants/lineItemStatus';
import orderFillType from 'common/constants/orderFillType';
import { useTheme } from '@mui/material/styles';
import { map } from 'lodash';
import PropTypes from 'prop-types';

const headCells = [
  {
    id: 'itemName',
    label: 'Drug Name',
  },
  {
    id: 'rxNumber',
    label: 'Rx Number',
  },
  {
    id: 'lineItemStatus',
    label: 'Item Status',
  },
  {
    id: 'fillType',
    label: 'Fill Type',
  },
  {
    id: 'form',
    label: 'Form',
  },
  {
    id: 'strength',
    label: 'Strength',
  },
  {
    id: 'quantity',
    label: 'Quantity',
  },
];

const OrderVerificationLineItemsRow = ({ lineItemRow, lineItems }) => {
  const theme = useTheme();
  return (
    <TableContainer sx={{ mb: 2, p: 2, ml: 30 }} key={`lineItemRow-${lineItemRow}`}>
      <Table size='small'>
        <TableHead>
          <TableRow hover>
            {headCells.map((headCell) => (
              <TableCell align='center' key={headCell.id} sx={{ fontWeight: '600' }}>
                {headCell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {map(lineItems, (item) => (
            <TableRow key={item.lineItemId} hover>
              <TableCell align='center'>{item.itemName}</TableCell>
              <TableCell align='center'>{item.rxNumber}</TableCell>
              <TableCell align='center'>{lineItemStatus[item.lineItemStatus]}</TableCell>
              <TableCell align='center'>
                <Chip
                  sx={{
                    background:
                      item.fillType === 'REFILL'
                        ? theme.palette.info.dark
                        : theme.palette.success.dark,
                    color: theme.palette.primary.contrastText,
                    borderRadius: '5px',
                    height: '25px',
                    width: '70px',
                  }}
                  label={
                    <Typography sx={{ fontSize: '1.1em' }}>
                      {orderFillType[item.fillType]}
                    </Typography>
                  }
                />
              </TableCell>
              <TableCell align='center'>{item.form}</TableCell>
              <TableCell align='center'>{item.strength}</TableCell>
              <TableCell align='center'>{item.quantity}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

OrderVerificationLineItemsRow.propTypes = {
  lineItemRow: PropTypes.string.isRequired,
  lineItems: PropTypes.arrayOf(
    PropTypes.shape({
      lineItemId: PropTypes.string,
      itemName: PropTypes.string,
      rxNumber: PropTypes.string,
      strength: PropTypes.string,
      form: PropTypes.string,
      quantity: PropTypes.string,
      fillType: PropTypes.string,
    })
  ).isRequired,
};

export default OrderVerificationLineItemsRow;
